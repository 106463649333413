<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="no-gutters auth-inner m-0">
      <!-- Left Text-->
      <b-col
          class="d-flex flex-column justify-content-between align-items-center auth-bg px-5 p-lg-5"
          lg="5">
        <div class="w-100 px-4">
          <b-col class=" px-xl-2 mx-auto d-flex align-items-center justify-content-between" lg="9" md="9" sm="8">
            <!--            <a href="https://www.halabk.sa"> -->
                          <logo-image-icon/>
            <!--            </a>-->
            <div></div>

            <div class="ml-2 mt-3">
              <wameed-menu
                  id="dropdown-1"
                  :items="orderByItems"
                  :title="orderBy.name"
                  activeItem="1"
                  classes=" text-reg-14"
                  fieldClasses="mb-0"
                  prependIcon="lang-icon"
                  text="name"
                  valueClasses="text-reg-14 text-font-secondary"
                  variant="no-color"
                  @dropdownClick="changeLang"
              />
            </div>
          </b-col>
        </div>
        <b-col class="d-flex align-items-center flex-1">
          <b-col class="px-xl-2 mx-auto flex-1" lg="9" md="9" sm="8">
            <div v-if="!$route.meta.noBackButton" class="">
              <wameed-btn
                  :classes="'change-icon-position px-0 text-medium-14 text-font-sub my-0'"
                  :title="$t('common.go_back_to_main')"
                  appendIcon="circle-prev-icon"
                  variant="link"
                  @onClick="goToMain()"
              />
            </div>
            <router-view/>
          </b-col>
        </b-col>
        <div class="d-flex flex-column align-items-center flex-0 pt-5" style="margin-bottom: 14px">
          <o-wameed-icon/>
        </div>
      </b-col>
      <!-- Login-->
      <b-col class="d-none d-lg-flex align-items-center p-5 login-bg" lg="7">

        <img src="@/assets/images/pages/auth.webp" alt="auth bg">

      </b-col>

      <!-- /Login-->
    </b-row>
    <wameed-loading-overlay :isLoading="getloading"/> 
  </div>
</template>

<script>
import {WameedBtn} from 'wameed-ui/dist/wameed-ui.esm';
import {WameedLoadingOverlay,WameedMenu}  from 'wameed-ui/dist/wameed-ui.esm'; 

import { mapGetters } from 'vuex'
export default {
  components: {
    WameedLoadingOverlay,
    WameedMenu,
    WameedBtn,
  },
  data: () => ({
    orderBy: {
      name: 'عربي',
      id: 'ar',
    },
    orderByItems: [
      {
        name: 'عربي',
        id: 'ar',
      },
      {
        name: 'English',
        id: 'en',
      },
    ],
  }),
  computed: {
    ...mapGetters({ getloading: 'getloading' }),
  },
  methods: {
    goToMain() {
      this.$router.push('/');
    },
    changeLang(item) {
      this.orderBy = item;

      this.$router.push({
        name: this.$router.currentRoute.name,
        params: {lang: item.id},
      });

      this.$router.go(0);
    },
  },

  created() {

    if (this.$router.currentRoute.params.lang === 'ar') {
      this.orderBy = this.orderByItems[0];
    } else {
      this.orderBy = this.orderByItems[1];
    }

  },
};
</script>
